 <template>
    <modal
        height="auto"
        :show="true"
        scrollable
        size="middle"
        @hide="$emit('commentHide')"
    >
        <div slot="header">
            <h2 class="font-normal">Add Comment</h2>
        </div>
        <div slot="body">
            <form @submit.prevent="">
                <modal-field label="Comment">
                    <textarea v-model="comment" rows="8"></textarea>
                </modal-field>

                <div class="flex items-center mt-3 cursor-pointer">
                    <input type="checkbox" v-model="internal" class="mr-2" id="internal">
                    <label for="internal" class="text-sm leading-tight text-gray-700 truncate">Internal</label>
                </div>
            </form>
        </div>
        <div slot="footer" class="w__100-p">
            <div class="flex justify-end w-full w__100-p">
                <div class="flex flex__justify-end">
                    <button class="px-10 btn-primary" @click="send" :disabled="loading">Send</button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import axios from 'axios';

export default {
    props: {
        value: {
            type: Object,
            default: () => {},
        },
    },

    name: 'CommentModal',

    data() {
        return {
            comment: '',
            internal: false,
            loading: false,
        }
    },

    methods: {
        async send() {
            this.loading = true;

            if (!this.comment) {
                this.$snotify.warning('Comment required!');

                this.loading = false;

                return
            }
            
            try {
                await axios.post(this.$apiUrl.comment, {
                    text: this.comment,
                    internal: this.internal,
                    commentable_id: this.value,
                    commentable_type: 'App\\Models\\Consignment',
                });

                this.$snotify.success(`Add comment successfully`);
                this.$emit('commentHide');
                this.$emit('refreshTable');
            } catch(error) {
                this.$snotify.error(error.response.data.message);
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>
